import React from 'react';

import { graphql } from 'gatsby';

import Hero from 'components/hero';
import Form from 'components/form';
import IconList from 'components/icon_list';

const RecrutamentoTemplat = (props) => {
  //PROPS
  const {
    data: {
      page: {
        hero: { title, text, subtitle },
        recruitment: { form, recruPros },
        cta,
      },
    },
  } = props;
  //PROPS

  return (
    <main className="main">
      <Hero data={{ title, subtitle, text }} center />
      <IconList data={recruPros} />
      <Form data={form} recruitment />
    </main>
  );
};

export const query = graphql`
  query Recrutamento($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      hero {
        title
        text
        subtitle
      }
      recruitment {
        form
        recruPros {
          recruIcon
          recruTitle
          recruText
        }
      }
      cta {
        ctaSubtitle
        ctaTitle
        type
        ctaCta {
          url
          title
          target
        }
        ctaImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default RecrutamentoTemplat;
